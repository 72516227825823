export class HostedSearchPageInitializer {
    private searchPageElement: HTMLElement;
    private searchEndpointUri: string;
    private analyticsEndpointUri: string;
    private organizationId: string;
    private searchHub: string;
    private isInExperienceEditor: boolean;

    private UNABLE_TO_RETRIEVE_ACCESS_TOKEN = 'Unable to retrieve access token.';

    public initializeSearchPage(element: HTMLElement): void {
        this.searchPageElement = element;
        this.searchEndpointUri = element.dataset.searchEndpointUri;
        this.analyticsEndpointUri = element.dataset.analyticsEndpointUri;
        this.organizationId = element.dataset.organizationId;
        this.searchHub = element.dataset.searchHub;
        this.isInExperienceEditor = element.dataset.isInExperienceEditor === 'true';

        this.getAccessToken = this.getAccessToken.bind(this);

        this.getAccessToken()
            .then((accessToken) => {
                this.initializeCoveo(element, accessToken);
            })
            .catch((err) => {
                if (this.isInExperienceEditor) {
                    this.displayErrorMessageInExperienceEditor(err.message);
                }
            });
    }

    private async getAccessToken() {
        return this.fetchAccessToken()
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }

                throw Error(this.UNABLE_TO_RETRIEVE_ACCESS_TOKEN);
            })
            .then((data) => data.token)
            .catch((err) => {
                if (this.isInExperienceEditor) {
                    this.displayErrorMessageInExperienceEditor(err.message);
                }

                return '';
            });
    }

    private initializeCoveo(element: HTMLElement, accessToken: string): void {
        const searchPageRoot: HTMLElement = element.querySelector('.CoveoSearchInterface');

        if (searchPageRoot && typeof Coveo !== 'undefined') {
            Coveo.SearchEndpoint.endpoints['default'] = new Coveo.SearchEndpoint({
                restUri: `${this.searchEndpointUri}/rest/search/`,
                accessToken: accessToken,
                queryStringArguments: {
                    organizationId: this.organizationId,
                },
                renewAccessToken: this.getAccessToken,
            });

            Coveo.Analytics.options.searchHub['defaultValue'] = this.searchHub;
            Coveo.Analytics.options.organization['defaultValue'] = this.organizationId;
            Coveo.Analytics.options.endpoint['defaultValue'] = `${this.analyticsEndpointUri}/rest/ua/`;

            Coveo.init(searchPageRoot);
        }
    }

    private async fetchAccessToken() {
        return await fetch(`/coveo/rest/token?t=${Date.now()}`);
    }

    private displayErrorMessageInExperienceEditor(message: string): void {
        const errorMessageElement = `
            <span class='errorMessage'>${message}</span>
        `;

        this.searchPageElement.insertAdjacentHTML('afterbegin', errorMessageElement);
    }
}
